<template>
    <div class="main">
        <!-- <div class="item-box y_bg">
            <div class="box  bgimg1">
                <div class="wow rollIn left left_box1">
                    <img class="icon" :src="require('../../assets/Duty/activity/2.png')"/>
                    <p class="text1">历思集团</p>
                    <div style="margin:1rem 0">
                        <p class="text2">WISDOM MATERIAL EVIDENCE </p>
                        <p class="text3">RELIABLE INDENTIFICAIOTN </p>
                    </div>
                    <p class="text4">智慧物证·可信鉴定 </p>
                    <p class="text5">SINCE 2006 </p>
                    
                </div>
                <div class="right wow rotateInDownRight hvr-backward">
                    <div class="right_box1">
                        <p class="text1">企业文化</p>
                        <p class="text2">CORPORATE CULTURE</p>
                        <p class="text3">历思定期组织各种别具特色的活动，为员工的业余生活增添乐趣与色彩</p> 
                    </div>
                </div>
            </div>
        </div> -->
        <div class="item-box b_bg">
            <div class="box box2">
                <div class="left wow rollIn img_left_box1">
                    <p class="text1">户外拓展 学习团队间的沟通协作</p>
                    <div class="line"></div>
                    <div class="content">
                        每个人做自我介绍，通过简单的互动，打破隔阂，快速进入状态。
                    </div>
                    
                </div>
                <div class="img_box wow rotateInDownRight">
                    <div class="box2">
                        <el-carousel class="img_el-carousel" :autoplay="true" indicator-position="outside">
                            <el-carousel-item class="img_el-carousel-item" v-for="item in data[0]"  :key="item">
                                <img class="img_item" :src="require('../../assets/Duty/activity/'+item)"/>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="item-box y_bg box3">
            <div class="box box2">
                
                <div class="img_box wow rotateInDownRight">
                    <div class="box2">
                        <el-carousel class="img_el-carousel" :autoplay="true" indicator-position="outside">
                            <el-carousel-item class="img_el-carousel-item" v-for="item in data[1]"  :key="item">
                                <img class="img_item" :src="require('../../assets/Duty/activity/'+item)"/>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>

                <div class="left wow rollIn img_left_box1">
                    <p class="text1">采摘荔枝 体验收获的快乐</p>
                    <div class="line"></div>
                    <div class="content">
                        夏至前后，荔枝成熟。进入果园后，大家被枝头一颗颗饱满的荔枝吸引了。采摘过程中，大家互相配合，不一会儿就得到满满的收获。
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            data:[
                ['1-1.jpg','1-2.jpg','1-3.jpg',],
                ['2-1.jpg','2-2.jpg','2-3.jpg',],
            ]
        }
    }
}
</script>
<style scoped>
.main{
    width:100%;
}
.item-box{
    /* height:30vw;
    max-height:490px; */
}
.box{
    width:80vw;
    margin: auto;
    height:100%;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    text-align: left;
    padding-left: 3rem;
    max-width: 1600px;
}
.left{
    /* width:20vw;
    min-width:300px; */
    padding-top:3vw;
    max-width:376px;
}
.right{
    background: url(/img/3.e8f4f390.png);
    background-size: cover;
    margin: 3rem 0;
    background-repeat: no-repeat;
    width: 45vw;
    background-position: 50% 50%;
    min-height: 250px;
    height: 23vw;
    max-height: 486px;
    max-width: 980px;
    display: flex;
    align-items: center;
    padding-left:3rem;
}
.icon{
    width:7.5rem;
}
.left_box1 .text1{
    font-weight: 700;
    font-style: normal;
    color: #A9A6AC;
    font-size: 1.4rem;
    margin-top: -2rem;
}
.left_box1 .text2{
    font-weight: 700;
    font-style: normal;
    color: #D5C7BA;
}
.left_box1 .text3{
    font-weight: 700;
    font-style: normal;
    color: #D5C7BA;
}
.left_box1 .text4{
    font-weight: 400;
    font-style: normal;
    color: #D5C7BA;
}
.left_box1 .text5{
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #E2CCA5;
    margin-top: 1rem;
}
.right_box1 .text1{
    font-weight: 700;
    font-style: normal;
    font-size: 25px;
    color: #3368C2;
}
.right_box1 .text2{
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
    color: #8A8D93;
    margin:1rem 0;
}
.right_box1 .text3{
    font-weight: 700;
    font-style: normal;
    font-size: 0.8rem;
    color: #8A8D93;
}
.y_bg{
    background:rgb(255 243 221)
}
.b_bg{
    background:rgb(16 88 194);
}
.bgimg1{
    background:url('../../assets/Duty/activity/1.png');
    background-size:contain;
    background-position:-6vw;
    background-repeat:no-repeat;
    justify-content: space-evenly;
}
@media screen and ( max-width:958px){
    .left{
        width:100%;
        text-align:center;
    }
    .right{
        margin: 3rem 0;
        background-repeat: no-repeat;
        width: 70vw;
        background-position: 50% 50%;
        min-height: unset;
        height: 33vw;
        max-height: unset;
        max-width: unset;
        
    }
    .box{
        padding:0;
    }
}
@media screen and ( max-width:541px){
    .right{
        height:353px;
        padding-right:3rem;
    }
}
.img_box{
    width:40vw;
    max-width: 650px;
}
.img_item{
    width:40vw;
    height:22vw;
    max-width: 650px;
    max-height:400px;
}
.img_el-carousel{
    height:inherit;
    max-width: 650px;
    margin:3rem 0 2rem 0;
}
/deep/.el-carousel__container{
    height:22vw;
    /* margin-top:2rem; */
    max-width: 650px;
    max-height:400px;
}
.img_left_box1{
    max-width: 500px;
    padding-right: 2rem;
    width: 34vw;
    min-width: 359px;
}
.img_left_box1 .text1{
    font-weight: 700;
    font-style: normal;
    color: #FFFFFF;
    font-size:2rem;
    
}
.line{
    width:100%;
    height:3px;
    background:#c3a676;
    margin-top:1rem;
}
.img_left_box1 .content{
    color:#fff;
    margin:2rem;
}
@media screen and (max-width:978px){
    .box2{
        padding:0
    }
    .img_left_box1{
        min-width: unset;
        width: 100%;
        padding:0;
        text-align:left;
        padding-top: 3rem;
    }
    .img_item{
        width:80vw;
        height:50vw;
    }
    /deep/.el-carousel__container{
        width:80vw;
        height:50vw;
    }
    .img_box{
        width:80vw;
    }
    .box3 .img_left_box1{
        padding-top:0px;
    }
}
.box3 .text1{
    color:#000;
}
.box3 .content{
    color:#000;
}
.box3 .img_left_box1{
    padding-right:0;
    padding-left:2rem;
}
</style>